import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'


import axios from 'axios';
import csrf_grabber from '../../components/csrf_grabber';


const ShortLinkRedirector = () => {
    var csrftoken = csrf_grabber('csrftoken');
    const location = useLocation();
    const [alert, set_alert] = useState(false);


    const get_complete_link = async () => {
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-CSRFToken': csrftoken
        };
        let short_link_id = location.pathname.split("/")
        short_link_id = short_link_id[short_link_id.length - 1]
        let body = JSON.stringify({ short_link_id })
        const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/saas/get-short-link-actual-value/`,
            body,
            { headers }
        );
        if (res.data.status == 1) {
            window.location.href = res.data.response

        } else {
            set_alert(res.data.response)
        }
    }
    useEffect(() => {
        get_complete_link()
    }, [])

    return (
        <Box> <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", alignItems:"center" }}>
            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes("[error]") ? "error" : "info"}
                    sx={{
                        p: 2,
                        zIndex: 9923213,
                    }}
                >
                         <Typography sx={{display: "flex", alignItems: "center", justifyContent: "center", alignItems:"center" }} variant='h3'>{alert}</Typography>
                    
                </Alert>
            ) : (
                <Typography sx={{display: "flex", alignItems: "center", justifyContent: "center", alignItems:"center" }} variant='h2'>Redirecting <span style={{padding:10}}><CircularProgress /></span></Typography>
            )}
           
             
            </Box>
        </Box>
    )
}

export default ShortLinkRedirector