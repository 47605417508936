import React, { useEffect, useState } from 'react'
import { Alert, Autocomplete, Box, Button, CircularProgress, LinearProgress, TextField, Typography } from '@mui/material'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from 'axios';
import csrf_grabber from '../../components/csrf_grabber';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import LockOpenIcon from '@mui/icons-material/LockOpen';


import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import WebScrappingInstructions from '../../static/media/WebScrappingInstructions.png';


const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const GoogleReviewLinkGeneratorUnStyled = () => {

    var csrftoken = csrf_grabber('csrftoken');
    const [alert, set_alert] = useState("");
    const [loading, set_loading] = useState(false);
    const [should_show_link, set_should_show_link] = useState(false);
    const [show_qr, set_show_qr] = useState(false);
    const [found_on_google, set_found_on_google] = useState(true);
    const [google_review_link, set_google_review_link] = useState("");
    const [url_of_your_business_on_google_search_result, set_url_of_your_business_on_google_search_result] = useState("");
    const [business_name, set_business_name] = useState("");
    const [location, set_location] = useState("");
    const [second_method, set_second_method] = useState("");
    const [scrapped_business_names, set_scrapped_business_names] = useState([]);
    const [name, set_name] = useState("");
    const [email, set_email] = useState("");
    const [phone_number, set_phone_number] = useState("");
    let timeoutId;
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);




    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                console.log(autocompleteService)
                console.log(autocompleteService.current)
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (business_name === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: business_name }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, business_name, fetch]);

    // const get_businesses = async () => {
    //     set_loading(true)
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json',
    //         'X-CSRFToken': csrftoken
    //     };

    //     let body = JSON.stringify({ business_name })
    //     const res = await axios.post(
    //         `${process.env.REACT_APP_BACKEND_URL}/saas/get-businesses/`,
    //         body,
    //         { headers }
    //     );
    //     if (res.data.status == 1) {
    //         set_loading(false)
    //         set_businesses(res.data.response);
    //     } else {
    //         set_loading(false)
    //         set_alert(res.data.response)
    //     }
    // }

    // const generate_google_review_link = () = async () => {
    //     set_loading(true)
    //     console.log(value)
    //     console.log(business_name)
    //     console.log(options)
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json',
    //         'X-CSRFToken': csrftoken
    //     };

    //     let body = JSON.stringify({ business_name })
    //     const res = await axios.post(
    //         `${process.env.REACT_APP_BACKEND_URL}/saas/get-business-google-review-link/`,
    //         body,
    //         { headers }
    //     );
    //     if (res.data.status == 1) {
    //         set_loading(false)
    //         set_google_review_link(res.data.response);

    //     } else {
    //         set_loading(false)
    //         set_alert(res.data.response)
    //     }
    // }

    const generate_google_review_link = async () => {
        set_loading(true)
        let review_link = `https://search.google.com/local/writereview?placeid=${value['place_id']}`
        set_google_review_link(review_link);
        set_loading(false)
    }

    const scrape_google_review_link = async () => {
        set_loading(true)
        set_alert(false)
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-CSRFToken': csrftoken
        };

        let body = JSON.stringify({ url_of_your_business_on_google_search_result })
        const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/saas/scrape-google-review-link/`,
            body,
            { headers }
        );
        if (res.data.status == 1) {
            set_loading(false)
            set_found_on_google(false)
            set_google_review_link(res.data.response);

        } else {
            set_loading(false)
            set_alert(res.data.response)
        }
    }

    const save_the_lead = async () => {
        set_loading(true)
        set_alert(false)
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-CSRFToken': csrftoken
        };

        let body = JSON.stringify({ business_name, email, name, phone_number, google_review_link, found_on_google })
        const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/saas/save-the-lead/`,
            body,
            { headers }
        );
        if (res.data.status == 1) {
            set_loading(false)
            set_should_show_link(true)
            set_google_review_link(res.data.response)

        } else {
            set_loading(false)
            set_alert(res.data.response)
            set_should_show_link(false)
        }
    }

    function downloadDivAsImage() {
        // Get the target div element
        const divElement = document.getElementById('qrcode');

        // Use html2canvas to capture the div element as a canvas
        html2canvas(divElement).then(function (canvas) {
            // Convert the canvas content to a data URL
            const dataURL = canvas.toDataURL('image/png');

            // Create a temporary anchor element
            const downloadLink = document.createElement('a');
            downloadLink.href = dataURL;
            downloadLink.download = `${business_name}'s Review link QR code.png`;

            // Trigger the download
            downloadLink.click();
        });
    }

    return (
        <Box sx={{ py: 4, width: '95%', margin: "auto",  }}>
            <Box sx={{ py: 2 }}>
                {loading ? <LinearProgress /> : ""}
            </Box>

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes("[error]") ? "error" : "info"}
                    sx={{
                        p: 2,
                        mb: 2,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}

            <Typography align='center' variant='h1'>{should_show_link ? "" : google_review_link ? "Link generated, please fill out the form to gain access." : "Get your Google review link"}</Typography>
            <Box sx={{ py: 3 }}>
                {should_show_link ?
                    <Box sx={{ bgcolor: "#F9F9F9", p: 2 }}>

                        <Typography align='center' variant='h2'>Link is ready!</Typography>
                    </Box>
                    : google_review_link ? <Box component={'form'} onSubmit={(e) => { e.preventDefault(); save_the_lead() }}>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <Typography component="label" for={"name"} variant="subtitle2">
                                Full Name <span style={{ color: "#e83030" }}>*</span>
                            </Typography>
                            <TextField
                                sx={{ bgcolor: "#e8e8e8" }}
                                variant="outlined"
                                name={name}
                                type="text"
                                id={"name"}
                                required
                                value={name}
                                onChange={(e) => set_name(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <Typography component="label" for={"name"} variant="subtitle2">
                                Email <span style={{ color: "#e83030" }}>*</span>
                            </Typography>
                            <TextField
                                sx={{ bgcolor: "#e8e8e8" }}
                                variant="outlined"
                                name={email}
                                type="email"
                                id={"email"}
                                required
                                placeholder="Your personal or active email"
                                value={email}
                                onChange={(e) => set_email(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <Typography component="label" for={"name"} variant="subtitle2">
                                Phone number <span style={{ color: "#e83030" }}>*</span>
                            </Typography>
                            <PhoneInput
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    style: { background: "#e8e8e8", width: '100%', height: '50px' }
                                }}

                                variant="outlined"
                                country={'us'}
                                value={phone_number}
                                onChange={phone => set_phone_number(phone)}
                            />

                        </Box>
                        <Button variant="contained" fullWidth type='submit'>Get My Review Link <LockOpenIcon /></Button>
                    </Box>
                        : <Box>
                            {second_method ? <Box component={'form'}>
                                <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                                    <Typography component="label" for={"name"} variant="subtitle2">
                                        URL of your business on google search result  <span style={{ color: "#e83030" }}>*</span>
                                    </Typography>
                                    <TextField
                                        sx={{ bgcolor: "#e8e8e8" }}
                                        variant="outlined"
                                        name={url_of_your_business_on_google_search_result}
                                        type="text"
                                        id={"url_of_your_business_on_google_search_result"}
                                        required
                                        value={url_of_your_business_on_google_search_result}
                                        onChange={(e) => set_url_of_your_business_on_google_search_result(e.target.value)}
                                    />
                                </Box>
                                <Box>
                                    <Accordion sx={{
                                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
                                    }}>
                                        <AccordionSummary
                                            sx={{ bgcolor: "#F9F9F9" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>How to find the URL</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography mb={2} variant="subtitle2">1. Search for your business on Google. Usually [business name] and [city] will do the trick.</Typography>
                                            <Typography mb={2} variant="subtitle2">2. Copy the URL of your business from google as shown in the picture below and paste that in the input field above.</Typography>
                                            <br />
                                            <img width={'100%'} style={{ cursor: "pointer" }} onClick={() => { window.open(`${process.env.REACT_APP_FRONTEND_URL}${WebScrappingInstructions}`) }} src={`${process.env.REACT_APP_FRONTEND_URL}${WebScrappingInstructions}`} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                                {/* <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                                    <Typography component="label" for={"name"} variant="subtitle2">
                                        Location <span style={{ color: "#e83030" }}>*</span>
                                    </Typography>
                                    <TextField
                                        sx={{ bgcolor: "#e8e8e8" }}
                                        variant="outlined"
                                        name={location}
                                        type="text"
                                        id={"location"}
                                        required
                                        value={location}
                                        onChange={(e) => set_location(e.target.value)}
                                    />
                                </Box> */}
                                {/* <Box mt={3}>
                                    {scrapped_business_names.length > 0 ?
                                        <Box >

                                            {scrapped_business_names[0] != "No business found" ? <Typography variant='subtitle1'>Select your business</Typography>
                                                : ""}
                                            {scrapped_business_names.map((business_name, index) => {
                                                return (
                                                    business_name == "No business found" ?
                                                        <Typography variant='subtitle2'>{business_name}</Typography>
                                                        :
                                                        <Box sx={{
                                                            bgcolor: index % 2 == 0 ? "#efefef" : "inherit", p: 2, "&:hover": {
                                                                bgcolor: "#e2e2e2",
                                                                cursor: "pointer"
                                                            }
                                                        }}
                                                            onClick={() => {
                                                                set_business_name(business_name)
                                                                set_location("")
                                                                set_scrapped_business_names([])

                                                            }}
                                                        >
                                                            <Typography variant='subtitle2'>{business_name}</Typography>
                                                        </Box>
                                                )
                                            })}
                                        </Box>
                                        : ""}
                                </Box> */}
                            </Box> :
                                <>
                                    <Autocomplete
                                        id="google-map-demo"
                                        getOptionLabel={(option) =>
                                            typeof option === 'string' ? option : option.description
                                        }
                                        filterOptions={(x) => x}
                                        options={options}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={value}
                                        noOptionsText="No business"
                                        onChange={(event, newValue) => {
                                            setOptions(newValue ? [newValue, ...options] : options);
                                            setValue(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            set_business_name(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Business Name with location" fullWidth />
                                        )}
                                        renderOption={(props, option) => {
                                            const matches =
                                                option.structured_formatting.main_text_matched_substrings || [];

                                            const parts = parse(
                                                option.structured_formatting.main_text,
                                                matches.map((match) => [match.offset, match.offset + match.length]),
                                            );

                                            return (
                                                <li {...props}>
                                                    <Grid container alignItems="center">
                                                        <Grid item sx={{ display: 'flex', width: 44 }}>
                                                            <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                                        </Grid>
                                                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                            {parts.map((part, index) => (
                                                                <Box
                                                                    key={index}
                                                                    component="span"
                                                                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                                                >
                                                                    {part.text}
                                                                </Box>
                                                            ))}
                                                            <Typography variant="body2" color="text.secondary">
                                                                {option.structured_formatting.secondary_text}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                            );
                                        }}
                                    />
                                    {/* <Autocomplete
                                        onInputChange={(event, value) => {
                                            clearTimeout(timeoutId);
                                            timeoutId = setTimeout(() => {
                                                set_business_name(value);
                                            }, 800);

                                        }}
                                        onChange={(event, value) => {
                                            set_business_name(value);
                                        }}

                                        id="controllable-states-demo"
                                        options={businesses}
                                        renderInput={(params) => <TextField {...params} label="Start typing and select your business from the dropdown" />}
                                    />      */}
                                </>
                            }
                            {second_method ? "" : <Typography sx={{ cursor: "pointer", color: "grey" }} onClick={() => set_second_method(true)}>
                                Can't find your business? <span style={{ color: "#ff0077", fontWeight: "bold" }}> Try this!</span>
                            </Typography>}
                        </Box>}
                {loading ?
                    <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                        <CircularProgress />
                    </Box>
                    : should_show_link ?
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", justifyContent: "center", pt: 2 }}>
                            <Box id="qrcode" sx={{ p: 2 }}>
                                <QRCode size={280} level="H" value={google_review_link} title={`${business_name}'s Review link`} fgColor='#042b48'
                                />
                            </Box>

                            <Typography variant='subtitle2'>
                                <Typography sx={{ color: "primary.main", textDecoration: "underline", cursor: "pointer" }} onClick={() => { downloadDivAsImage() }}> Click here </Typography> to download the QR code
                            </Typography>
                        </Box>
                        : ""}
                <Box sx={{ py: 2 }}>

                    {loading ? "" : should_show_link ?
                        <Box >
                            <Box sx={{ bgcolor: "#F9F9F9", p: 1, overflow: "hidden" }}>
                                <Typography
                                    id="copy_btn"
                                    align='center'
                                    sx={{ cursor: "pointer", whiteSpace: "nowrap", }}
                                    onClick={() => {
                                        let copy_form_url = document.getElementById("copy_btn");
                                        navigator.clipboard.writeText(google_review_link);
                                        copy_form_url.style.background = "#042b48";
                                        copy_form_url.style.color = "#F9F9F9";
                                        setTimeout(() => {
                                            copy_form_url.style.color = "#042b48";
                                            copy_form_url.style.background = "transparent";
                                        }, 500);
                                    }}>{google_review_link}</Typography>
                            </Box>
                            <Typography align='center' variant='subtitle2'>Click above link to copy it</Typography>
                        </Box>
                        // <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        //     <Box sx={{ flex: 1 }}>
                        //         <Button variant="outlined" fullWidth
                        //             id="copy_btn"
                        //             onClick={() => {
                        //                 let copy_form_url = document.getElementById("copy_btn");
                        //                 navigator.clipboard.writeText(google_review_link);
                        //                 copy_form_url.style.background = "#042b48";
                        //                 copy_form_url.style.color = "white";
                        //                 setTimeout(() => {
                        //                     copy_form_url.style.color = "#042b48";
                        //                     copy_form_url.style.background = "transparent";
                        //                 }, 500);
                        //             }}>Copy link</Button>
                        //     </Box>
                        //     <Box sx={{ flex: 1 }}>
                        //         <Button variant="contained" color="secondary" fullWidth onClick={() => { set_show_qr(true) }}>Get QR code</Button>
                        //     </Box>
                        // </Box>
                        :
                        google_review_link ? "" :
                            <Button variant="contained" disabled={business_name == "" && url_of_your_business_on_google_search_result == "" ? true : false} fullWidth onClick={() => {
                                if (second_method) {

                                    scrape_google_review_link()
                                } else {
                                    generate_google_review_link()
                                }
                            }}>Generate Review Link

                            </Button>
                    }




                </Box>
            </Box>
        </Box>
    )
}


export default GoogleReviewLinkGeneratorUnStyled