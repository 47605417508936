import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./hocs/Layout";
import './App.css';

// Base containers
import LandingPage from "./containers/base/LandingPage";
import TermsAndConditions from "./containers/base/TermsAndConditions";
import PrivacyPolicy from "./containers/base/PrivacyPolicy";
import CookiePolicy from "./containers/base/CookiePolicy";
import PageNotFound from "./containers/base/PageNotFound";
import DomainNotAllowed from "./containers/base/DomainNotAllowed";

// Authentication containers
import GoogleReviewLinkGenerator from './containers/saas/GoogleReviewLinkGenerator';
import GoogleReviewLinkGeneratorUnStyled from './containers/saas/GoogleReviewLinkGeneratorUnStyled';
import ShortLinkRedirector from './containers/saas/ShortLinkRedirector';
import axios from "axios";


const App = () => {
  const mounted = useRef();
  const [is_domain_allowed, set_is_domain_allowed] = useState(true);
  const [allowed_domains, set_allowed_domains] = useState([]);
  const [is_parent_domain, set_is_parent_domain] = useState(false);


  const get_allowed_domains = async () => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/settings/get-allowed-domains/`,
      { headers }
    );
    set_allowed_domains(res.data)
  }
  useEffect(() => {
    get_allowed_domains()
  }, [])

  useEffect(() => {
    // Get the current domain from the window location object
    const currentDomain = window.location.hostname;
    // Check if the current domain is in the allowedDomains array

    // console.log(isAllowedDomain(allowed_domains), currentDomain)
    if (allowed_domains.includes(currentDomain)) {
      set_is_domain_allowed(true)
    }else{
      set_is_domain_allowed(false)
    }

    if (currentDomain == "app.myreviewlink.net"){
      set_is_parent_domain(true)
    }
    console.log("currentDomain")
    console.log(currentDomain)

    // Your React application code here...
  }, [allowed_domains]);


  // useEffect(() => {
  //   if (!mounted.current) {
  //     window.gtag("config", "UA-216385747-1", {
  //     page_title: window.location.pathname,
  //     page_path: window.location.pathname,
  //     })
  //     mounted.current = true;
  //   } else {
  //     // do elementDi<dUpdate /> logic
  //   }
  // });


  return (
    <Router>
      <Layout is_parent_domain={is_parent_domain} >
        {is_domain_allowed ?
          <Routes>
            {/* // Base Routes */}
            {is_parent_domain ? 
            <Route exact path="*" element={<GoogleReviewLinkGenerator />} />
            : 
            <Route exact path="*" element={<GoogleReviewLinkGeneratorUnStyled />} />
            }
            <Route exact path="/l/:uid" element={<ShortLinkRedirector />} />

            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/cookie-policy" element={<CookiePolicy />} />

            {/* // SaaS routes */}



          </Routes>
          : <Routes>
            <Route exact path="*" element={<DomainNotAllowed />} />

          </Routes>
        }
      </Layout>
    </Router>
  );
};

export default App;
