import { getRadioUtilityClass } from "@mui/material";
import {
  createTheme,
  adaptV4Theme,
  responsiveFontSizes,
} from "@mui/material/styles";

export let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#042b48",
    },
    secondary: {
      main: "#c2e2d9",
    },
    contrast: {
      main: "#f9b031",
    },
    customBox: {
      default: "#f7f7f7 ",
      themeAdaptive: "#202020 ",
    },
    blackAndWhite: "#000000",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'gray_button' },
          style: {
            margin: "0px !important",
            fontFamily: "avenir roman,sans-serif !important",
            lineHeight: "1.5 !important",
            height: '43px !important',
            letterSpacing: "0.00938em !important",
            boxShadow: "none !important",
            transition: "0.1s  !important",
            textTransform: "none !important",
            border:"1px solid rgba(34,34,34,0.1 !important)",
            color: "#717171",
            fontWeight:  "500 !important",
            fontSize: "14px !important",

            pl: "4 !important",
            pr: "4 !important",
            "&:hover": { backgroundColor: "rgba(34,34,34,0.1)", boxShadow: "none !important" },
          },
        },

      ],


      styleOverrides: {

        root: ({ ownerState }) => ({
          borderRadius: "24 !important",
          py: "1 !important",
          whiteSpace:"nowrap !important",
          minWidth:"100px !important",
          color:"white !important",
          px: "4 !important",
          height: '43px !important',
          ...(ownerState.variant === 'contained' && ownerState.color === 'secondary' && {
            boxShadow: "none !important",
            transition: "0.1s !important",
            fontWeight: "600 !important",
            fontSize: "16px !important",
            textTransform: "none !important",
            "&:hover": { boxShadow: "none !important" },
          }),
        }),
      },
    },
  },

  typography: {
    cursor:"auto",
    fontFamily: [
      "Helvetica Neue !important",
      "Helvetica !important",
      "Arial !important",
      "sans-serif !important",
      "Circular Bold !important",
      "sans-serif !important",
    ].join(","),
    fontSize: "14 !important",
    color: "#222",
    button: {
      textTransform: "none !important",
      color: "white !important",
      fontWeight: "bold !important"
    },
  },
});

theme = responsiveFontSizes(theme);
theme.typography.h1 = {
  fontSize: "30px !important",
  fontWeight: "600 !important",
  textAlign: "center !important",
  fontFamily: "Avenir Heavy,sans-serif !important",
};
theme.typography.h2 = {
  fontSize: "24px !important",
  fontWeight: "600 !important",
  textAlign: "center !important",
  fontFamily: "Avenir Heavy,sans-serif !important",
};

theme.typography.h3 = {
  fontSize: "20px !important",
  fontWeight: "bold !important",
  lineHeight: "25px !important",
  fontFamily: "Avenir Heavy,sans-serif !important",
};

theme.typography.subtitle1 = {
  fontSize: "small !important",
  fontWeight: "300 !important",
  lineHeight: "25px !important",

  color: "gray !important",
  fontFamily: "avenir heavy,sans-serif !important",
};

theme.typography.subtitle2 = {
  margin: "0px !important",
  fontFamily: "avenir roman,sans-serif !important",
  fontWeight: "400 !important",
  fontSize: "16px !important",
  lineHeight: "1.5 !important",
  letterSpacing: "0.00938em !important",
  color: "gray !important",
};


