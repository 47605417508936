import React, { useEffect, useState } from 'react'

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { theme } from "./ThemeConfig";

const Layout = ({ children, is_parent_domain }) => {
  const location = useLocation().pathname;
  const [show_footer, set_show_footer] = useState();
  

  useEffect(() => {
    if (location.includes("dashboard")){
      set_show_footer(false)
    }else{

      set_show_footer(true)
    }
  }, [location])
  

  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{minHeight: is_parent_domain ? "100vh" : "initial", bgcolor: is_parent_domain ? "#F9F9F9" : "white", boxShadow:0, }} >
        {/* <Navbar /> */}
        {children}
        {/* {show_footer ? 
        <Footer />
        :""} */}
      </Paper>
    </ThemeProvider>
  );
};

export default Layout
