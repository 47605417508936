import React from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const DomainNotAllowed = () => {
    return (
        <div className='container'>
            <div style={{ "display": "inline-flex", "flexDirection": "column", "height": "100vh", "width": "100%", "justifyContent": "center", "alignItems": "center" }}>
                
                <Typography variant="h1" fontWeight="800" align="center">
                    Domain not allowed!
                </Typography>
            </div>
        </div>
    );
};

export default DomainNotAllowed;
